<template>
  <MyOrdersStatusInfoButton
    v-if="isInfoButtonVisible"
    v-bind="{ status }"
    class="mr-md"
    type="playslip"
  />
  <MyOrdersStatusBadgeSingleOrder v-if="isBadgeVisible" v-bind="{ status }" />
</template>

<script lang="ts" setup>
import { PlaySlipOrderStatus } from '~/@types/generated/backend/graphql-schema-types'

type MyOrdersStatusProps = {
  status: PlaySlipOrderStatus
}

const props = defineProps<MyOrdersStatusProps>()

const isBadgeVisible = computed(
  () => props.status !== PlaySlipOrderStatus.Closed,
)
const isInfoButtonVisible = computed(
  () =>
    ![PlaySlipOrderStatus.Cancelled, PlaySlipOrderStatus.Closed].includes(
      props.status,
    ),
)
</script>
