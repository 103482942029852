<template>
  <LcBadge v-bind="CONFIG[status]">
    {{ $t(`myorders.status.playslip.${status}`) }}
  </LcBadge>
</template>

<script lang="ts" setup>
import type { LcIconName } from '@lottocom/frontend-components'

import { PlaySlipOrderStatus } from '~/@types/generated/backend/graphql-schema-types'

type MyOrdersStatusBadgeSingleOrderProps = {
  status: PlaySlipOrderStatus
}

type StatusBadge = {
  class: string
  icon: LcIconName
}

defineProps<MyOrdersStatusBadgeSingleOrderProps>()

const CONFIG: Record<PlaySlipOrderStatus, StatusBadge> = {
  [PlaySlipOrderStatus.Cancelled]: {
    class: 'bg-danger100',
    icon: 'orders-canceled',
  },
  [PlaySlipOrderStatus.Closed]: {
    class: 'bg-danger100',
    icon: 'orders-purchased',
  },
  [PlaySlipOrderStatus.Ordered]: {
    class: 'bg-secondary300',
    icon: 'orders-waiting-for-purchase',
  },
  [PlaySlipOrderStatus.Purchased]: {
    class: 'bg-secondary400',
    icon: 'orders-purchased',
  },
}
</script>
